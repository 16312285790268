.discs {
  background-color: #1e1e1e;
  color: yellow;
  min-height: 100vh;
  margin-bottom: 100px;

  h1 {
    padding: 40px;
    border: 4px solid;
    margin: 40px 0;
  }

  @media screen and (max-width: 500px) {
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 3rem;
    font-family: "Amatic SC", cursive;
    cursor: pointer;
    animation: ceon infinite 1.3s;
  }

  h3 {
    color: yellow;
  }

  ul {
    padding: unset;
  }

  .-cursive {
    font-family: "League Script", cursive;
  }

  .neon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sign {
      font-size: 60px;
      color: #f24a9f;
      animation: neon infinite 1.3s;
    }
    p.sign {
      font-size: 30px;
      font-family: "Amatic SC", cursive;
      color: #8bed90;
      width: 500px;
      text-align: center;
      animation: peon infinite 1.3s;
    }
  }

  @keyframes neon {
    0%,
    100% {
      text-shadow: 0 0 2px rgba(#ce0026, 1), 0 0 25px rgba(#ce0026, 1),
        0 0 20px rgba(#fff, 1), 0 0 30px rgba(#fb0000, 1);
    }

    70% {
      text-shadow: 0 0 20px rgba(#ce0026, 1), 0 0 20px rgba(#fff, 1);
    }
  }

  @keyframes peon {
    0%,
    100% {
      text-shadow: 0 0 2px rgba(#00ce0e, 1), 0 0 15px rgba(#00ce0e, 1),
        0 0 10px rgba(#fff, 1), 0 0 10px rgba(#00ce0e, 1);
    }

    70% {
      text-shadow: 0 0 10px rgba(#00ce0e, 1), 0 0 10px rgba(#fff, 1);
    }
  }

  @keyframes ceon {
    0%,
    100% {
      text-shadow: 0 0 2px rgba(#fbf46e, 1), 0 0 15px rgba(#fbf46e, 1),
        0 0 10px rgba(#fff, 1), 0 0 10px rgba(#fbf46e, 1);
    }

    70% {
      text-shadow: 0 0 10px rgba(#fbf46e, 1), 0 0 10px rgba(#fff, 1);
    }
  }

  .playlist-wrapper {
    border: 4px solid #2493f2;
    //max-width: 700px;
    width: 650px;
    margin: auto;
    padding: 10px 0;
    margin-bottom: 20px;
    font-family: "Amatic SC", cursive;
    font-size: 1.5rem;

    h3 {
      color: #73dfff;
      cursor: pointer;
    }

    li {
      list-style: none;
      background: #2493f2;
      border: 2px solid darkblue;
      max-width: 80%;
      padding: 10px 0;
      margin: 20px auto;
    }
  }

  .playlist-link {
    text-align: center;

    a {
      color: yellow;
      text-decoration: none;
    }
  }

  .playlist-link-small {
    text-align: center;
    font-size: 1rem;

    a {
      color: yellow;
      text-decoration: none;
    }
  }

  video {
    width: 300px;
    height: 50px;
  }

  .song-info {
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
    }

    .song-info__text {
      padding: 0 20px;
    }
  }

  .song-info:nth-child(odd) {
    flex-direction: row-reverse;
  }

  li:nth-child(even) {
    .song-info {
      flex-direction: row;
    }
  }

  .bar-chart {
    width: 500px;
    height: 500px;
  }

  .vertical-chart {
    width: 400px;
    height: 1500px;
  }

  .stats-wrapper {
    margin: auto;
    text-align: center;
    //border: 4px solid #2493f2;
    //max-width: 700px;
    width: 650px;
    margin: auto;
    //font-size: 1.5rem;
    //border-bottom: 2px solid #73dfff;
  }

  .stats,
  .playlists,
  .playlist-wrapper ul {
    display: none;
  }

  .stats {
    margin-bottom: 150px;
  }

  .stats-description {
    max-width: 80%;
    margin: 1em auto;
    //color: #61f7ff;
    color: #73dfff;
    font-family: "Amatic SC", cursive;
    font-size: 1.5rem;
  }

  path[name="No The"] {
    fill: rgb(23, 25, 202);
  }

  path[name="The"] {
    fill: rgb(247, 21, 216);
  }

  .stats-view {
    cursor: pointer;
  }

  .stats-heading {
    margin-top: 100px;
    font-family: "Amatic SC", cursive;
    font-size: 2rem;
  }

  .recharts-wrapper {
    color: initial;
    margin: auto;

    text {
      fill: white;
    }
  }

  ul.playlist-mms {
    display: block;
  }

  @media screen and (max-width: 500px) {
    .playlist-wrapper {
      width: unset;
    }

    .stats-wrapper {
      width: unset;
      border: unset !important;
    }

    .neon-container p.sign {
      width: 50%;
    }
  }
}

footer {
  text-align: center;
  color: #6f6f6f;
}
