body {
  font-family: sans-serif;
  margin: 0;
  background-color: white;
}

h1,
h2,
h3,
.video-wrapper {
  text-align: center;
}

.wrapper {
  display: flex;
}

.banner {
  padding: 10px 0;
  background: #003670;
  color: #fff;
  border-bottom: 1px solid black;
  width: 100%;
}

.title {
  text-align: center;
  font-size: 30px;
  padding-top: 60px;
  background: transparent;
}

.details {
  height: 150vh;
  width: 400px;
  background: #d7ebff;
  border-right: 1px solid black;
}

text {
  font-size: 20px;
}

.rd3t-node circle {
  fill: #abcdef;
}

.rd3t-leaf-node circle.family-link-node {
  fill: #f4b0f4;
}

.rd3t-leaf-node circle {
  fill: white;
}

.family-name {
  padding: 10px 0;
  font-size: 40px;
  text-align: center;

  .family-additional-info {
    font-size: 16px;
  }
}

.banner p,
.deets p,
.deets h3 {
  padding: 5px 10px;
}

.mobile {
  display: none;
}

@media screen and (max-width: 450px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .tree {
    width: 100vw !important;
    height: 60vh !important;
  }

  .details {
    width: 100vw;
    height: unset;
    border-right: none;
    border-top: 1px solid black;
  }
}
